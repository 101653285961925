button:
  about: 关于
  back: 返回
  go: 确定
  home: 首页
  toggle_dark: 切换深色模式
  toggle_langs: 切换语言
created-at: 创建时间
email-not-valid: 邮箱未验证
fee: 费
intro:
  aka: 也叫
  desc: 固执己见的 Vite 项目模板
  dynamic-route: 动态路由演示
  hi: 你好，{name}
  whats-your-name: 输入你的名字
login: 登入
login-with-metamask: 登录和 MetaMask
logout: 登出
next: 次
not-found: 未找到页面
order-id: 订单 ID
order-status: 状态
otp-code-not-valid: OTP 验证码错误
otp-verified: OTP 确认成功
otp-verified-desc: 你已经成功验证了 OTP，现在你可以使用你的 OTP 代币登录了
otp-verified-desc2: 请记住，你需要在每次登录时都输入 OTP 代币
please-enter-email-address: 请输入邮箱地址
please-enter-otp-code: 请输入 OTP 验证码
please-enter-otp-to-login: 请输入 OTP 验证码
price: 价
product-name: 名
refunded-amount: 退款额
start-bind-email: 绑邮箱
start-setup-otp: 开始设置 OTP
start-verify-email: 验证邮箱
submit-email-verify-token: 提交
submit-otp-code: 提交
tx-status.0: Pending
tx-status.1: 成功
tx-status.2: 败
tx-status.3: 已退款
tx-status.4: 过期
tx-status.5: PartialRefund
tx-status.6: PendingRefund
updated-at: 更新时间
meow: 喵
